.modal-info {
    p {
        margin: 0;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-right: 20px;

        .category {
            .category-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 10px;

                .category-title {
                    font-size: 24px;
                }

                .category-percentage {
                    font-size: 18px;
                }
            }

            .content {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .card {
                    width: 150px;
                    border: 1px solid #d0d0d0;
                    border-radius: 10px;
                    text-align: center;
                    padding: 10px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 60px;

                    .text-value {
                        font-weight: 600;
                        font-size: 18px;
                    }
                }

                .progress-container {
                    flex: 1;
                    border: 1px solid #d0d0d0;
                    border-radius: 10px;
                    height: 60px;

                    .progress-inner {
                        height: 100%;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}
