.welcome-page {
    text-align: center;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .title {
        font-size: 24px;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
        }
    }
}
