.message-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .divider {
        width: 100%;
        height: 0.5px;
    }

    &[data-origin="received"] {
        align-items: flex-start;

        .message-inner {
            background-color: #ffffff;
            color: #454545;
            border-radius: 13px 13px 13px 0;
        }

        .divider {
            background-color: #454545;
        }
    }

    &[data-origin="sent"] {
        align-items: flex-end;

        .message-inner {
            background-color: #454545;
            color: #ffffff;
            border-radius: 13px 13px 0 13px;
        }

        .divider {
            background-color: #ffffff;
        }
    }

    .message-inner {
        border: 1px solid #454545;
        min-width: 200px;

        .message {
            padding: 0 13px;
        }

        .image-preview {
            width: 400px;
            border-radius: 13px 13px 0 0;
            cursor: pointer;
        }

        .file-message {
            .text, .title {
                cursor: pointer;
            }
        }

        .reply-message {

            .header {
                display: flex;
                flex-direction: row;
                gap: 10px;
                padding: 13px 13px 0 13px;

                p {
                    margin: 0;
                    font-style: italic;
                }

                svg {
                    height: 20px;
                }
            }

            .in-reply-to {
                background-color: red;
                margin: 13px 13px 0 13px;
                padding: 5px;
                background-color: #CCC;
                border: 1px solid #AAA;
                border-left: 5px solid #AAA;
                border-radius: 13px;
            }
        }

        .title {
            font-weight: bold;
            margin-left: 20px;
            cursor: pointer;
        }

        .text {
            max-width: 400px;
            padding: 0 13px;
        }

        &[data-selected="true"] {
            border: 4px solid #1f6bf1;
        }

        .interactive-message {
            display: flex;
            flex-direction: column;

            .header {
                font-weight: bold;
                padding: 13px 13px 8px 13px;
            }

            .body {
                padding: 8px 13px 8px 13px;
            }

            .action {
                text-align: center;
                padding: 8px 13px 13px 13px;
                font-weight: 600;
            }
        }

        .interactive-list {
            .title {
                text-align: left;
                font-weight: normal;
            }

            .list {
                .row {
                    text-align: center;
                    padding: 13px;
                }
            }
        }
    }

    .message-info {
        .datetime {
            font-size: 10px;
        }
    }
}

.message-menu {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;

    .option {
        background-color: #b2defd;
        border: 1px solid #454545;
        color: #454545;
        text-align: center;
        height: 40px;
        padding: 10px 16px;
        border-radius: 19px;

        p {
            margin: 0;
        }
    }
}
