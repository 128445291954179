.home-container {
    display: flex;
    flex-direction: column;
    background: #e0e0e0;

    .inner {
        flex: 1;
        display: flex;
        flex-direction: row;
    }
}
