.audio-container {
    padding: 20px 15px;

    .audio-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        .play-button {
            background-color: transparent;
            border: 0;
            width: 30px;
            text-align: center;
        }

        .center {
            .progress {
                height: 8px;
                width: 280px;
                max-width: 90vw;
            }

            .times {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                p {
                    margin: 0;
                    font-size: 14px;
                }
            }
        }

        .speed {
            background: transparent;
            border: 1px solid white;
            border-radius: 5px;
            width: 40px;
            height: 30px;
            cursor: pointer;
        }
    }

    &[data-color="white"] {
        color: white;

        .audio-inner {
            .play-button {
                path {
                    fill: white;
                }
            }

            .speed {
                border-color: white;
                color: white;
            }
        }
    }

    &[data-color="dark"] {
        color: #454545;

        .audio-inner {
            .play-button {
                path {
                    fill: #454545;
                }
            }

            .speed {
                border-color: #454545;
                color: #454545;
            }
        }
    }
}
