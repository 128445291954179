.chat-container {
    max-height: calc(100vh - 70px);
    overflow-y: none;
    flex: 1;

    .chat-top {
        .chat-header {
            background: white;
            padding: 20px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            .contact-info {
                flex: 1;

                p {
                    margin: 0;
                }

                .name {
                    font-size: 16px;
                    margin-bottom: 5px;
                    font-weight: 600;
                }

                .identity {
                    font-size: 15px;

                    span {
                        cursor: pointer;
                    }
                }
            }

            .actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                
                .bds-icon {
                    cursor: pointer;
                }
            }
        }

        .chat-search {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            border-top: 0.5px solid var(--color-surface-3, #cfcfcf);
            background-color: white;
            padding: 15px 10px;

            .input {
                flex: 1;
            }

            bds-icon {
                cursor: pointer;
            }

            .result {
                flex: 1;
                padding-left: 15px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .text {
                    flex: 1;
                }
            }
        }
    }

    .chat-messages {
        padding: 20px;
        overflow-y: auto;
        max-height: calc(100vh - 90px - 70px);

        &[data-search-opened="true"] {
            max-height: calc(100vh - 90px - 70px - 70px);
        }

        .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 5px;

            p {
                margin: 0;
            }

            .info-1 {
            }

            .info-2 {
                font-size: 1.25rem;
            }
        }
    }
}
