.warn-flag {
    height: 40px;
    display: flex;
    flex: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}
