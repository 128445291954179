.contacts-list-container {
    width: 30vw;
    // height: 100vh;
    border-right: 0.5px solid var(--color-surface-3, #cfcfcf);

    &[data-full="true"] {
        width: 100vw;
    }

    .header {
        height: 90px;
        background: white;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .search {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
    }

    .list {
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: calc(100vh - 90px - 70px);
        overflow-y: auto;

        &[data-flags="1"] {
            max-height: calc(100vh - 90px - 70px - (40px * 1));
        }

        &[data-flags="2"] {
            max-height: calc(100vh - 90px - 70px - (40px * 2));
        }


        &[data-flags="3"] {
            max-height: calc(100vh - 90px - 70px - (40px * 3));
        }

        .contact {
            background-color: #cecece90;
            border: 4px solid transparent;
            border-radius: 13px;
            padding: 6px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            p {
                margin: 0;
            }

            .name {
            }

            .identity {
                font-size: 10px;
            }

            &[data-selected="true"] {
                border: 4px solid #1f6bf1;
            }
        }
    }

    .instruction {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
    }
}
