.copyright-container {
    height: 70px;
    padding: 10px 0;
    border-top: 0.5px solid var(--color-surface-3, #cfcfcf);

    .copyright-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}
